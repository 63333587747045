import { Navigate } from '@remix-run/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { makeGamePackScheduleLink } from '../../app/components/GamePack/utils';
import { Loading } from '../components/Loading';
import { OTPCheckoutLayout } from '../components/OneTimePurchase/OTPCheckoutLayout';
import {
  useMyOrganizer,
  useUpdateMyOrganizer,
} from '../components/Organization/hooks/organization';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { apiService } from '../services/api-service';
import { type Organizer } from '../types';
import { err2s } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export function clientLoader() {
  return {};
}

type FormData = {
  firstName: string;
  lastName: string;
};

function Container(props: {
  organizer: Organizer;
  onChange: (updated: Organizer) => void;
}) {
  const { organizer, onChange } = props;

  const { register, handleSubmit, formState, setFocus } = useForm<FormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  });

  const {
    call: onSubmit,
    state: { error },
  } = useLiveAsyncCall(
    handleSubmit(async (data: FormData) => {
      const resp = await apiService.organization.updateOrganizer(
        organizer.orgId,
        organizer.uid,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      );
      onChange(resp.data.organizer);
    })
  );

  useEffect(() => {
    setFocus('firstName');
  }, [setFocus]);

  return (
    <form
      className='        
        max-w-140 lg:bg-modal text-white 
        rounded lg:rounded-2.5xl 
        px-5 lg:px-17 py-0 lg:py-10
        flex flex-col items-center
      '
      onSubmit={onSubmit}
    >
      <h2 className='text-2xl font-medium'>Please enter your name</h2>

      <div className='mt-7.5 w-full'>
        <div className='mt-2.5 flex justify-center items-center gap-5'>
          <label>
            <input
              className={`w-full h-12.5 ${
                formState.errors.firstName ? 'field-error' : 'field'
              } mb-0`}
              placeholder='First Name'
              {...register('firstName', {
                required: true,
                maxLength: 50,
              })}
              maxLength={50}
            />
          </label>
          <label>
            <input
              className={`w-full h-12.5 ${
                formState.errors.lastName ? 'field-error' : 'field'
              } mb-0`}
              placeholder='Last Name'
              {...register('lastName', {
                required: true,
                maxLength: 50,
              })}
              maxLength={50}
            />
          </label>
        </div>
      </div>

      <div className='mt-9 ml-2 h-3 w-full text-3xs text-red-002 text-left truncate'>
        {err2s(error)}
      </div>

      <button
        type='submit'
        className={`mt-1 w-full btn-primary h-12.5 flex items-center justify-center`}
        disabled={formState.isSubmitting}
      >
        {formState.isSubmitting && (
          <Loading text='' containerClassName='mr-2' />
        )}
        Continue
      </button>
    </form>
  );
}

export function Component() {
  const { pack } = useCheckoutLoaderData();
  const organizer = useMyOrganizer();
  const updateOrganizer = useUpdateMyOrganizer();

  if (!organizer || (organizer.firstName && organizer.lastName)) {
    return <Navigate to={makeGamePackScheduleLink(pack)} replace />;
  }

  return (
    <OTPCheckoutLayout
      pack={pack}
      progress={80}
      checkAccessible={false}
      containerClassName='!w-full h-full !items-start'
      background={false}
    >
      <Container organizer={organizer} onChange={updateOrganizer} />
    </OTPCheckoutLayout>
  );
}
